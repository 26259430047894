import { App } from 'vue';
import PrimeVue from 'primevue/config';
import Editor from 'primevue/editor';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import OverlayPanel from 'primevue/overlaypanel';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import InputSwitch from 'primevue/inputswitch';
import Checkbox from 'primevue/checkbox';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';
import Timeline from 'primevue/timeline';
import ScrollPanel from 'primevue/scrollpanel';
import ProgressBar from 'primevue/progressbar';
import Tooltip from 'primevue/tooltip';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';
import SelectButton from 'primevue/selectbutton';
import Badge from 'primevue/badge';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import TieredMenu from 'primevue/tieredmenu';
import Avatar from 'primevue/avatar';
import Rating from 'primevue/rating';
import Breadcrumb from 'primevue/breadcrumb';
import Tag from 'primevue/tag';
import Chips from 'primevue/chips';
import Sidebar from 'primevue/sidebar';
import Toolbar from 'primevue/toolbar';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import VirtualScroller from 'primevue/virtualscroller';
import InputGroup from 'primevue/inputgroup';
import Paginator from 'primevue/paginator';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Steps from 'primevue/steps';

export const registerPrimeVue = (app: App) => {
  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.directive('tooltip', Tooltip);
  app.component('Editor', Editor);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Button', Button);
  app.component('Toast', Toast);
  app.component('InputText', InputText);
  app.component('OverlayPanel', OverlayPanel);
  app.component('ConfirmDialog', ConfirmDialog);
  app.component('DataTable', DataTable);
  app.component('Column', Column);
  app.component('TabView', TabView);
  app.component('TabPanel', TabPanel);
  app.component('Dropdown', Dropdown);
  app.component('Skeleton', Skeleton);
  app.component('ProgressSpinner', ProgressSpinner);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Dialog', Dialog);
  app.component('MultiSelect', MultiSelect);
  app.component('Calendar', Calendar);
  app.component('InputSwitch', InputSwitch);
  app.component('Checkbox', Checkbox);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Image', Image);
  app.component('FileUpload', FileUpload);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Textarea', Textarea);
  app.component('Card', Card);
  app.component('Timeline', Timeline);
  app.component('ScrollPanel', ScrollPanel);
  app.component('ProgressBar', ProgressBar);
  app.component('RadioButton', RadioButton);
  app.component('Message', Message);
  app.component('SelectButton', SelectButton);
  app.component('Badge', Badge);
  app.component('Password', Password);
  app.component('Panel', Panel);
  app.component('TieredMenu', TieredMenu);
  app.component('Avatar', Avatar);
  app.component('Rating', Rating);
  app.component('Breadcrumb', Breadcrumb);
  app.component('Tag', Tag);
  app.component('Chips', Chips);
  app.component('Sidebar', Sidebar);
  app.component('Menubar', Menubar);
  app.component('Toolbar', Toolbar);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Menu', Menu);
  app.component('InputNumber', InputNumber);
  app.component('VirtualScroller', VirtualScroller);
  app.component('InputGroup', InputGroup);
  app.component('Paginator', Paginator);
  app.component('Accordion', Accordion);
  app.component('AccordionTab', AccordionTab);
  app.component('Steps', Steps);
};
