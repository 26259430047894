<template>
  <template v-if="!loading">
    <img v-show="!!imageSrc && imageSrc != ''" v-bind="$attrs" ref="image" :src="imageSrc" alt="Server Image" />
    <img v-show="!src" v-bind="$attrs" :src="fallbackImage ? fallbackImage : PlaceHolderImage" alt="Skeleton Image" />
  </template>
  <Skeleton v-else v-bind="$attrs" :width="width || '100%'" :height="height || '1rem'" :shape="shape" />
</template>

<script lang="ts" setup>
import axios from 'axios';
import { onMounted, ref, watch } from 'vue';
import PlaceHolderImage from '../../assets/Staff-Dashboard/image-place-holder.png';

const image = ref<HTMLImageElement | null>();
const loading = ref(false);
const imageSrc = ref('');

const props = defineProps<{
  src: string | undefined;
  width?: string;
  height?: string;
  customer?: boolean;
  fallbackImage?: string;
  shape?: 'rectangle' | 'circle';
}>();

const getImage = (src: string) => {
  if (!src) {
    return;
  }
  let prefix = '';
  if (props.customer) {
    prefix = '/panel/customer';
  }
  loading.value = true;

  axios
    .get(`${prefix}/get-asset/${src}`, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] as string });
      imageSrc.value = URL.createObjectURL(blob);
      image.value!.onload = function () {
        URL.revokeObjectURL(image.value!.src);
      };
    })
    .catch(() => {})
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.src,
  (newValue) => {
    getImage(newValue!);
  }
);

onMounted(() => {
  getImage(props.src!);
});
</script>
