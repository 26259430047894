import axios, { AxiosResponse } from 'axios';
import { GlobalSearchModelSchema, GlobalSearchResponseSchema, ServiceInterface } from '../../types/Global';

export default class GlobalSearchService implements ServiceInterface {
  getModels(): Promise<AxiosResponse<GlobalSearchModelSchema[]>> {
    return axios.get('/global/search/list');
  }
  search(query: string, models: number[], fullUrl: string | null): Promise<AxiosResponse<GlobalSearchResponseSchema>> {
    return axios.post(fullUrl || '/global/search', {
      query,
      models
    });
  }
}
