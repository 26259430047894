import axios from 'axios';
import router from '../router';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

const logout = async (redirect = true) => {
  if (redirect) localStorage.setItem('redirect', window.location.pathname);
  if (cookies.isKey('XSRF-TOKEN')) {
    axios.post('/auth/logout').catch(() => {});
    cookies.remove('XSRF-TOKEN');
  }
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('isDark');
  return await router.push({ name: 'Login' });
};

export { logout };
