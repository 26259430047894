import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import { registerPrimeVue } from './utilities/primeVueUtility';
import { registerFontawesome } from './utilities/fontawesome';
import { mainAxios } from './utilities/axios';
import router from './router/index';
import './assets/general/main.scss';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import { emitter } from './utilities/mitt';

mainAxios();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(router);
registerFontawesome(app);
registerPrimeVue(app);
Sentry.init({
  app,
  dsn: import.meta.env.VITE_DNS as string,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ]
});
app.mount('#app');
