<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import useEmitter from './composables/useEmitter';
import { onMounted } from 'vue';
const emitter = useEmitter();

const loadTheme = (isDark: boolean) => {
  const theme: HTMLLinkElement = document.getElementById('style-theme') as HTMLLinkElement;
  const loginTypeSelector = window.location.hostname.toLowerCase();
  let baseThemeUrl = '';

  if (loginTypeSelector.startsWith(import.meta.env.VITE_CUSTOMER_PORTAL_DOMAIN as string)) {
    if (isDark) {
      baseThemeUrl = 'customer/dark';
    } else {
      baseThemeUrl = 'customer/light';
    }
    theme.href = `/${baseThemeUrl}/theme.css`;
  } else if (loginTypeSelector.startsWith(import.meta.env.VITE_STAFF_PORTAL_DOMAIN as string)) {
    if (isDark) {
      baseThemeUrl = 'staff/dark';
    } else {
      baseThemeUrl = 'staff/light';
    }
    theme.href = `/${baseThemeUrl}/theme.css`;
  }
};

emitter?.on<'load-theme'>('load-theme', loadTheme);

onMounted(() => {
  const isDark = !!localStorage.getItem('isDark');
  loadTheme(isDark);
});
</script>
