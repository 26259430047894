<template>
  <router-view v-if="props.isStaffPortal"></router-view>
  <ConfirmDialog></ConfirmDialog>
  <Toast position="top-center" />
</template>
<script setup lang="ts">
import { globalAxiosErrorHandler } from '../utilities/globalHelpers';
import useEmitter from '../composables/useEmitter';
import { useToast } from 'primevue/usetoast';
import { AxiosError } from 'axios';
import { ErrorResponseType, toastSchema } from '../types/Global';
import { getCurrentInstance, onBeforeMount } from 'vue';

const emitter = useEmitter();

const instance = getCurrentInstance()!;
onBeforeMount(() => {
  const isDark = !!localStorage.getItem('isDark');
  instance.appContext.config.globalProperties.$isDark = isDark;

  emitter?.emit('load-theme', isDark);
});
const props = defineProps({
  isStaffPortal: {
    type: Boolean,
    required: false,
    default: false
  }
});

const toast = useToast();
emitter!.on<'toast'>('toast', (data: toastSchema) => showToast(data));
emitter!.on<'toast-axios'>('toast-axios', (error: AxiosError<ErrorResponseType>) => showAxiosToast(error));
emitter!.on<'toast-promise'>('toast-promise', (error: string) => showPromiseToast(error));

const showAxiosToast = (error: AxiosError<ErrorResponseType>) => {
  toast.add({
    severity: 'error',
    summary: globalAxiosErrorHandler(error).summary,
    detail: globalAxiosErrorHandler(error).detail,
    life: 6000
  });
};
const showPromiseToast = (error: string) => {
  toast.add({
    severity: 'error',
    summary: error,
    detail: '',
    life: 6000
  });
};

const showToast = (data: toastSchema) => {
  switch (data.type) {
    case 'info':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Attention',
        detail: data.message,
        life: 2000
      });
      break;
    case 'success':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Successful operation',
        detail: data.message,
        life: 3000
      });
      break;
    case 'warn':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Warning',
        detail: data.message,
        life: 4000
      });
      break;
    case 'error':
      toast.add({
        severity: data.type,
        summary: data.title ? data.title : 'Error',
        detail: data.message,
        life: 6000
      });
      break;
  }
};
</script>
